<template>

	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">
				<el-button size="mini" icon="el-icon-plus" @click="formAdd" type="primary"
					style="margin-left: 20px;float: right;">分配审批人</el-button>
			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>教师巡访</el-breadcrumb-item>
					<el-breadcrumb-item>巡访审批配置</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">


			<el-row type="flex" justify="start">
				<el-col :span="24">

					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>

								实习班级列表</span>



						</div>

						<div class="treeContainer">

							<el-tree ref="clsTree" :data="treeData" show-checkbox :props="firstCascaderProps"
								node-key="id" :default-expand-all="true" :expand-on-click-node="false">
								<div class="custom-tree-node flexBetween" slot-scope="{ node, data }"
									style="width: 100%;">
									<div>
										<span :style="{ backgroundColor: node.data.biye == 1 ? '#ccc' : '' }">{{
												node.data.name
										}}</span>

										<span class="nodetag" style="background-color:plum"
											v-if="node.data.type == 'bj'">班</span>
										<span class="nodetag" style="background-color:purple"
											v-if="node.data.type == 'sxxz'">组</span>

										<div class="teacherItem"
											v-if="node.data.teacher_arr && node.data.teacher_arr.length > 0">
											<el-tag @close="removeTeacher(tag)" size="mini" style="margin-left:10px"
												v-for="tag in node.data.teacher_arr" :key="tag.name" closable>
												{{ tag.username }}
											</el-tag>
										</div>
									</div>




								</div>
							</el-tree>
						</div>
					</el-card>
				</el-col>
			</el-row>
			<el-dialog title="请选择审批人" :visible.sync="isShowModal" width="600px" :close-on-click-modal="false"
				:destroy-on-close="true" custom-class="cus_dialog">
				<el-select v-model="TeacherID" filterable remote clearable reserve-keyword placeholder="请输入教师姓名"
					:remote-method="searchTeacher" style="width:80%">
					<el-option v-for="item in teacherList" :key="item.id"
						:label="item.username + (item.dept_name ? '[' + item.dept_name + ']' : '')"
						:value="item.teacher_id">
					</el-option>
				</el-select>

				<div style="padding:20px">
					<el-button icon="el-icon-check" @click="saveTeacher" type="primary"
						style="margin-left: 20px;float: right;">保存</el-button>
				</div>
			</el-dialog>




		</div>
	</div>
</template>
<script>

export default {
	data() {
		return {
			isShowModal: false,
			multipleSelection: [],
			treeData: [],
			firstCascaderProps: {
				label: "name",
				children: "children",
			},
			TeacherID: "",
			teacherList: []

		}
	},
	mounted() {
		this.getTree();
	},
	methods: {
		getList() {

		},
		getTree() {

			this.$http.post("/api/visit_audit_tree", { parent_id: 0, biye: 0 }).then(res => {
				this.treeData = res.data.tree
				this.ckdTreeData = this.treeData
			})
		},
		formAdd() {

			let data = this.$refs['clsTree'].getCheckedKeys(true)
			if (data.length > 0) {
				this.isShowModal = true
			} else {
				this.$message.error("请选择班级")
			}

		},
		searchTeacher(e) {
			let params = {
				page: 1,
				pagesize: 20,
				ktype: "username",
				keyword: e
			};
			// sys_dept_user_related_list
			this.$http.post("/api/t_sch_teachers_list", params).then((res) => {
				for (let item of res.data.data) {
					if (item.dept_roles && item.dept_roles.length > 0) {
						item.dept_name = item.dept_roles.map(e => { return e.name }).join(",")
					}
					item.teacher_id = item.id
				}
				this.teacherList = res.data.data;

			});
		},
		saveTeacher() {
			if (!this.TeacherID) {
				this.$message.error("请选择教师")
			} else {
				let data = this.$refs['clsTree'].getCheckedKeys(true)
				this.$http.post("/api/teacher_class_visit_edit", {
					teacher_id: this.TeacherID,
					class_ids: data.join(",")
				}).then((res) => {
					this.getTree()

					this.$message.success("设置成功！")
					this.isShowModal = false

				});
			}
		},
		removeTeacher(tag) {
			console.log(tag)



			this.$http.post("/api/teacher_class_visit_delete", {
				id: tag.related_id
			}).then((res) => {
				this.getTree()

				this.$message.success("删除成功！")


			});
		}


	}
}
</script>

<style scoped>
.nodetag {
	display: inline-block;
	padding: 0 5px;
	font-size: 12px;
	transform: scale(0.8, 0.8);
	background-color: #409eff;
	color: #fff;
	border-radius: 4px;
}

.teacherItem {
	display: inline-block;
	margin-left: 10px;
}
</style>
